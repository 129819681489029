import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

const CustomCollapse = ({elem, currentItem, setCurrentItem}) => {

    const openCollapse = () => {
        if(elem === currentItem){
            setCurrentItem(null)
        }else{
            setCurrentItem(elem)
        }
    }
  return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                    className={elem === currentItem ? "accordion-button" : "accordion-button collapsed"} type="button"
                    onClick={() => openCollapse()}
                    aria-controls="example-collapse-text"
                    aria-expanded={elem === currentItem}
                >
                    {elem.title}<i className="fas fa-plus-circle"></i> <i className="fas fa-minus-circle"></i>
                </button>
            </h2>
            <Collapse in={currentItem === elem}>
                <div id="example-collapse-text">        
                    <div className="accordion-body">
                        <p dangerouslySetInnerHTML={{ __html: elem.description }}></p>
                    </div>
                </div>
            </Collapse>
        </div>
  );
};

export default CustomCollapse;
