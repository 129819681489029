import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageTop from '../../components/PageTop'
import ServiceDetailContainer from '../../components/ServiceDetailContainer'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { useLangContext } from '../../i18n/ProvideLang';
import ServiceServices from '../../services/general/serviceServices';
import { Helmet } from 'react-helmet';

const ServiceDetail = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ServiceServices.view, slug, null, lang);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/services/${slug}`)
            }
        }
    }, [lang])

  return (
    <>
      <Helmet>
        <title>{info?.title}</title>
      </Helmet>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
        <div>
            <PageTop item={info}/>
            {info && <ServiceDetailContainer info={info}/>}
        </div>
      }
    </>
  )
}

export default ServiceDetail