import React from 'react'
import ServiceCard from './simple/ServiceCard';
import useFetchItems from '../hooks/useFetchItems';
import ServiceServices from '../services/general/serviceServices';

const Services2 = ({item}) => {
    const {
        items,
    } = useFetchItems(ServiceServices.index, item.ids)

  return (
    <section className="service-4 ptb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                <div className="row justify-content-center">
                    {items &&
                        items.items.map((card, index) =>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index} >
                                <ServiceCard item={card} border={true}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services2