import React from 'react'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
  } from "react-share";
import { useLangContext } from '../../i18n/ProvideLang';

const ShareBlock = () => {
    const shareUrl = window.location.href;
    const {localizationsItems} = useLangContext();

  return (
    <div className="blog-text-footer mt-30">
        <div className="tag-area">
            <span>{localizationsItems?.share}:</span>
        </div>
        <div className="social-icons">
            <ul>
                <li>
                    <FacebookShareButton url={shareUrl}>
                        <i className="fab fa-facebook-f"></i>
                    </FacebookShareButton>
                </li>
                <li>
                    <TwitterShareButton url={shareUrl}>
                        <i className="fab fa-twitter"></i>
                    </TwitterShareButton>
                </li>
                <li>
                    <TelegramShareButton url={shareUrl}>
                        <i className="fab fa-telegram"></i>
                    </TelegramShareButton>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default ShareBlock