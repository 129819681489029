import React, { useEffect, useState } from 'react'

const TeamCard2 = ({card}) => {

  return (
    <div className="col-12">
        <div className="row team-card2" >
            <div className="col-sm-12 col-md-4 col-lg-4">
                <img src={card?.image} alt="image"/>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8 team-card-text">
                <h4>{card?.name}</h4>
                <p className='position'>{card?.position}</p>
                <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
            </div>
        </div>
    </div>
  )
}

export default TeamCard2