import React, { useState } from 'react'
import VideoModal from './complex/VideoModal'
import CustomCollapse from './simple/CustomCollapse'

const FaqSpoiler = ({item}) => {
    const [modalOpen, setModalOpen] = useState();
    const [currentItem, setCurrentItem] = useState();

  return (
        <section className="faq ptb-70">
            <div className="container">
                <div className="row">
                    <div className="default-section-title default-section-title-middle mb-3">
                        <h3>{item.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                    </div>
                    {(item.image || item.video) &&
                        <div className="col-lg-6">
                            <div className="faq-img-area">
                                {item.image && <img src={item.image} alt="image"/>}
                                {item.video && <div className="video-popup" onClick={() => setModalOpen(true)}><i className="fas fa-play"></i></div>}
                            </div>
                        </div>
                    }
                    <div className="col-lg-6">
                        <div className="faq-text-area">
                            <div className="accordion" id="accordionExample">
                                {item.elements?.map((elem, index) => 
                                    <CustomCollapse elem={elem} key={index} currentItem={currentItem} setCurrentItem={setCurrentItem}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VideoModal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                onRequestClose={() => setModalOpen(false)}
                videoUrl={item.video}
            />
        </section>
  )
}

export default FaqSpoiler