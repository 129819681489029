import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const EventCard = ({card}) => {
  const navigate = useNavigate();
  const {lang, localizationsItems} = useLangContext();

  const handleView = (item) => {
      navigate({
          pathname: `/${lang}/events/${item.slug}`
      })

  }

  return (
    <div className="events-card" onClick={() => handleView(card)}>
        <img src={card.preview_image} alt="image"/>
        <div className="events-card-text">
            <ul>
                <li>{card.date}</li>
            </ul>
            <h4>{card.title}</h4>
            <div className="read-more-btn">{localizationsItems?.more}</div>
        </div>
    </div>
  )
}

export default EventCard