import React from 'react';
import Modal from 'react-modal'
import './ModalComponent.scss'
import Utils from "../../services/utils";


const ModalComponent = ({children, closeModal, disableCloseBtn, isWhite, ...props}) => {
    const body = document.body;

    function afterOpenModal() {
        body.style.paddingRight = Utils.getScrollBarWidth() + "px";
        body.style.overflowY = "hidden";
    }

    function afterCloseModal() {
        body.style.removeProperty('padding-right');
        body.style.removeProperty('overflow-y');
    }

    return (
        <Modal
            {...props}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            className={isWhite ? "ReactModal__Content--custom" : "ReactModal__Content--custom2"}
        >
            {!disableCloseBtn && <div className="close-btn" onClick={closeModal}>x</div>}
            {children}
        </Modal>
    );
};

export default ModalComponent;