import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import img1 from "../../images/main-banner.png";
import OwlCarousel from 'react-owl-carousel';

const OpeningSlider = ({item}) => {
    const [imgFront, setImgFront] = useState();
    const [imgBack, setImgBack] = useState();
    const [socials, setSocials] = useState();

    const { 
        items,
      } = useFetchItems(AppServices.settings);

    useState(() => {
        setImgFront(item.elements.find(item => item.type == 'banner-front')?.image)
        setImgBack(item.elements.find(item => item.type == 'banner-back')?.image)
    }, [item])

    useEffect(() => {
        setSocials(items?.find(it => it.type === "socials")?.value)
    }, [items])

    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 3000,
        autoplayTimeout:8000,
        autoplay: true,
        loop: true,
        dots: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        }
    }


  return (
    <OwlCarousel className='opening-slider-1 owl-carousel' {...options}>
        {item.elements?.map((elem, index) => 
            <div className="main-banner plr-100 main-banner-color" key={index}>
                <div className="banner-social-icons">
                    <ul>
                        {socials?.map((social, index) => 
                            social.value &&
                            <li key={index}><a href={social.value} target="_blank">
                                <i className={
                                            social.type === "facebook" ? "fab fa-facebook-f"
                                            :
                                            social.type === "twitter" ? "fab fa-twitter"
                                            :
                                            social.type === "instagram" ? "fab fa-instagram"
                                            :
                                            social.type === "youtube" ? "fab fa-youtube"
                                            :
                                            social.type === "telegram" ? "fab fa-telegram"
                                            :
                                            social.type === "whatsapp" ? "fab fa-whatsapp"
                                            :
                                            "fab fa-facebook-f"
                                }></i>
                            </a></li>
                        )}
                    </ul> 
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-text-area banner-text-area-1 banner-img-area-1">
                                <h6>{elem.subtitle}</h6>
                                <h1>{elem.title}</h1>
                                <p dangerouslySetInnerHTML={{ __html: elem.description }}></p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img-area-1">
                                <img src={elem.front_image} alt="image"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-banner-before'>
                    <img src={elem.back_image} alt="" />
                </div>
                <div className='main-banner-after'>
                    <img src={img1} alt="" />
                </div>
            </div>
        )}
    </OwlCarousel>
  )
}

export default OpeningSlider