import React, { useEffect, useState } from 'react'

const ProcessCard = ({card}) => {
  return (
    <div className="process-card">
        <div className="icon-img" alt='image' >
              <img src={card.image} alt="" />
        </div>
        <h4>{card.title}</h4>
        <p>{card.subtitle}</p>
    </div>
  )
}

export default ProcessCard