import React from 'react'
import useFetchItems from '../hooks/useFetchItems'
import ServiceServices from '../services/general/serviceServices'
import Pagination from './Pagination'
import ServiceCard from './simple/ServiceCard'

const ServicesGrid = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
      } = useFetchItems(ServiceServices.index)


  return (
        <section className={"service pt-70 pb-70"}>
            <div className="container">
                <div className="section-content">
                    <div className="row justify-content-center">
                        {items?.items &&
                            items.items.map((item, index) => 
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                                    <ServiceCard item={item}/>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='mt-30'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </section>
  )
}

export default ServicesGrid