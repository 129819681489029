import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import GalleryModal from './GalleryModal';

const Gallery = ({item}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [activeItem, setActiveItem] = useState();

    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 2000,
        autoplay: true,
        loop: true,
        dots: false,
        nav: true,
        navText: ['<i class="fas fa-long-arrow-alt-left"></i>', '<i class="fas fa-long-arrow-alt-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        }
    }

    const handleShow = (index) => {
        setModalOpen(true);
        setActiveItem(index);
    }

  return (
    <section className="ptb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                {item.is_slider ?
                    <div>
                        {item.elements && 
                            <OwlCarousel className='gallery-slider owl-carousel gallery-slider' {...options}>
                                {item?.elements.map((image, index) =>
                                    <img src={image} alt="" key={index} onClick={() => handleShow(index)}/>
                                )}
                            </OwlCarousel>}
                    </div>
                :
                    <div className="row justify-content-center">
                        {item &&
                            item.elements.map((image, index) =>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mr-b-10" key={index} >
                                    <img src={image} alt="" className='gallery-img'/>
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        </div>
        <GalleryModal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            onRequestClose={() => setModalOpen(false)}
            items={item?.elements}
            activeItem={activeItem}
        />
    </section>
  )
}

export default Gallery