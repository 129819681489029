import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import { BLOG_ROUTE } from '../../navigation/Constants';

const NewsCard = ({item}) => {
    const navigate = useNavigate();
    const {lang, localizationsItems} = useLangContext();

    const handleView = (item) => {
        navigate({
            pathname: `/${lang}/articles/${item.slug}`
        })

    }

  return (
    <div className="blog-card" onClick={() => handleView(item)}>
        <div className="blog-card-img">
            <img src={item.preview_image} alt="image"/>
        </div>
        <div className="blog-card-text-area">
            <div className="blog-date">
                <ul>
                    <li><i className="far fa-calendar-alt"></i> {item.date}</li>
                </ul>
            </div>
            <h4>{item.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: item.description}}></p>
            <div className="read-more-btn" >{localizationsItems?.more}</div>
        </div>
    </div>
  )
}

export default NewsCard