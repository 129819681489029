import React, { useEffect, useState } from 'react'

const WhyWe = ({item}) => {
    const [imgs, setImgs] = useState();
    const [texts, setTexts] = useState();
    const [type, setType] = useState();

    useEffect(() => {
        setImgs(item.elements.find(it => it.type == "images").elements)
        setType(item.elements.find(it => it.type == "images").elements.length > 1 ? true : false)
        setTexts(item.elements.find(it => it.type == "text").elements)
    }, [item])

  return (
    <section className="we-are pb-70 pt-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                    {type ?
                        <div className="about-img">
                            {imgs?.map((img, index) => 
                                <img className={`a-img-${index+1}`} src={img} alt="image" key={index}/>
                            )}
                        </div>
                        :
                        <div className="why-we-img">
                            {imgs?.map((img, index) => 
                                <img src={img} alt="image" key={index}/>
                            )}
                        </div>
                    }
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div className="why-we-text-area about-text-area-2">
                        <div className="default-section-title">
                            <span>{item.subtitle}</span>
                            <h3>{item.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        {texts?.map((text, index) => 
                            <div className="why-we-text-list" key={index}>
                                <i >
                                    <img src={text.image} alt=''/>
                                </i>
                                <h4>{text.title}</h4>
                                <div dangerouslySetInnerHTML={{ __html: text.description }}></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhyWe