import React from 'react'
import useFetchItems from '../hooks/useFetchItems'
import EventsServices from '../services/general/eventsServices'
import Pagination from './Pagination'
import EventCard from './simple/EventCard'

const EventsGrid = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index, null)

  return (
        <section className="events pt-70 pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    {items?.items?.map((item, index) => 
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}> 
                            <EventCard card={item} key={index} />
                        </div>
                    )}
                </div>
                <div className='mt-30'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </section>
  )
}

export default EventsGrid