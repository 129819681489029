import http from "./http-common"
import {getData} from "../handlers";

export default class ProjectServices {
    static index = (queryString) => {
        console.log(`/app/projects${queryString ? `?${queryString}` : ""}`)
        return getData(http, `/app/projects${queryString ? `?${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/projects/categories${queryString ? `&${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/projects/${slug}${queryString ? `${queryString}` : ""}`);
    }
}