import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLangContext } from '../i18n/ProvideLang';
import AppServices from '../services/general/appServices';

const FormContact = ({item}) => {
    const [loading, setLoading] = useState();
    const [visible, setVisible] = useState(false);
    const {localizationsItems} = useLangContext();

    const {handleSubmit, register, reset, formState: {errors}} = useForm();

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {}
        }

        for(var key in data){
            let temp = item.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
            console.log(res)
        }else{
            console.log(res)
        }
        setLoading(false)
    }

  return (
        <section className="contact-form-area pb-70 pt-70">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <h3>{item?.title}</h3>
                    {item && 
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                    }
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className={loading ? "contact-form-text-area isLoading" : "contact-form-text-area"}>
                                <form onSubmit={handleSubmit(CustomSubmit)}>
                                    <div className="row align-items-center">
                                        {item.elements?.map((elem, index) =>
                                            <div className="col-md-12" key={index}>
                                                <div className="form-group">
                                                    <label>{elem.label}</label>
                                                    <input {...register(elem.name)} type="text" className="form-control" placeholder={elem.placeholder} name={elem.name}/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-md-12 col-sm-12 col-12">
                                            <button className="default-button" type="submit"><span>{localizationsItems?.form_template_button}</span></button>
                                        </div>
                                    </div>
                                    <div className={visible ? 'form-success form-visible' : 'form-success'}>
                                        {localizationsItems?.form_templete_success}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default FormContact