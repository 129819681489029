import React, {useState} from 'react';
import ModalComponent from "./ModalComponent";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const GalleryModal = ({closeModal, items, activeItem, ...props}) => {

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false} isWhite={false}>
                <div className='slider-gallery'>
                    <Carousel showThumbs={false} showStatus={false} selectedItem={activeItem}>
                        {items?.map((image, index) =>
                            <div key={index} >
                                <img src={image} alt="" />
                            </div>
                        )}
                    </Carousel>
                </div>
        </ModalComponent>
    );
};

export default GalleryModal;