import React, { useEffect } from 'react'
import errorImg from "../../images/error.png"
import { useLangContext } from '../../i18n/ProvideLang';
import { MAIN_PAGE_ROUTE } from '../../navigation/Constants';
import { NavLink, useNavigate } from 'react-router-dom';

const Error = () => {
    const {localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang} = useLangContext();
  
    useEffect(() => {
      if(lang){
        navigate(`/${lang}`)
      }
    }, [lang])

  return (
    <div>
        <section className="error ptb-100">
            <div className="container">
                <div className="error-content">
                    <img src={errorImg} alt="image"/>
                    <h4>{localizationsItems?.error_title}</h4>
                    <p>{localizationsItems?.error_content}</p>
                    <NavLink className="default-button" to={MAIN_PAGE_ROUTE}>{localizationsItems?.error_back}<i className="fas fa-angle-right"></i></NavLink>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Error