import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import useFetchItems from '../hooks/useFetchItems'
import { BLOG_ROUTE } from '../navigation/Constants'
import ArticleServices from '../services/general/articleServices'
import Pagination from './Pagination'
import NewsCard from './simple/NewsCard'

const BlogGrid = () => {
    const navigate = useNavigate();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

  return (
        <section className="blog-details pt-70 pb-70">
            <div className="container">
                <div className="row ">
                    {items?.items &&
                        items.items.map((item, index) => 
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                                <NewsCard item={item} key={index}/>
                            </div>
                        )
                    }
                </div>
                <div className='mt-30'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </section>
  )
}

export default BlogGrid