import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang';

const PopularPublication = ({items}) => {
  const navigate = useNavigate();
  const {lang, localizationsItems} = useLangContext();

  const handleView = (item) => {
    navigate({
        pathname: `/${lang}/articles/${item.slug}`
    })
  }

  return (
    <div className="sidebar-card recent-news mt-30">
        <h3>{localizationsItems?.last_articles}</h3>
        {items.items.slice(0,6).map((item, index) => 
            <div className="recent-news-card" key={index} onClick={() => handleView(item)}>
                <img src={item.image} alt="image"/>
                <h5>{item.title}</h5>
                <p>{item.date}</p>
            </div>
        )}
    </div> 
  )
}

export default PopularPublication