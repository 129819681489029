import React, { useEffect, useRef } from 'react'
import useOnScreen from '../hooks/useOnScreen.hook'
import CountUp from 'react-countup';

const StatisticArea = ({item, type}) => {
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

  return (
    <div>
        <section className={type ? "fun-facts pt-70 pb-70" : "fun-facts fun-facts-1 pt-70 pb-70"}>
            <div className="container">
                <div className="row" ref={ref}>
                    {item?.elements.map((elem, index) => 
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 last-card" key={index}>
                            <div className={type ? "fun-facts-card fun-facts-card-2" : "fun-facts-card"}>
                                <div className="icon-img">
                                    <img src={elem.image} alt="" />
                                </div>
                                {elem.counter ? 
                                    <h2><span className="odometer" data-count="46712" ref={ref}>
                                        {isVisible ? <CountUp start={0} end={elem.counter} duration={2.75}/> : <>00</>} {elem.title}
                                    </span></h2>
                                    :
                                    <h3 dangerouslySetInnerHTML={{ __html: elem.title}}>
                                    </h3>
                                }
                                <p> {elem.subtitle}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </div>
  )
}

export default StatisticArea