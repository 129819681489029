import React from 'react'
import ContactItem from '../../components/ContactItem'
import PageTop from '../../components/PageTop'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'

const Contacts = () => {
  const {
    items,
    loading,
    pageCount,
    params,
    handlePageClick,
    setFilterData
  } = useFetchItems(AppServices.page, 'contact')

  return (
    <div>
        <PageTop item={items}/>
        <ContactItem item={items}/>
    </div>
  )
}

export default Contacts