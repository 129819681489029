import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Events from '../../components/Events'
import HotNews from '../../components/HotNews'
import PageTop from '../../components/PageTop'
import ProcessWork from '../../components/ProcessWork'
import ProjectsGrid from '../../components/ProjectsGrid'
import Services from '../../components/Services'
import Services2 from '../../components/Services2'
import StatisticArea from '../../components/StatisticArea'
import Team from '../../components/Team'
import WhyWe from '../../components/WhyWe'
import useFetchItems from '../../hooks/useFetchItems'
import { useLangContext } from '../../i18n/ProvideLang'
import AppServices from '../../services/general/appServices'
import { Helmet } from 'react-helmet'

const Projects = () => {
  const {lang} = useLangContext();
  const navigate = useNavigate();
  const {lang: lang2} = useParams();

  const {
    items,
    loading,
  } = useFetchItems(AppServices.page, 'projects')

  useEffect(() => {
    if(lang){
        if(lang !== lang2) {
            navigate(`/${lang}/projects`)
        }
    }
  }, [lang])

  return (
    <>
      <Helmet>
        <title>{items?.title}</title>
      </Helmet>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
        <div>
            <PageTop item={items}/>
            <ProjectsGrid/>
            {items &&
              items.elements?.map((elem, index) => 
                <div key={index}>
                  {
                    elem.type === "about" ?
                    <WhyWe item = {elem}/>
                    :
                    elem.type === "advantages" ?
                    <StatisticArea item = {elem}/>
                    :
                    elem.type === "events" ?
                    <Events item = {elem}/>
                    :
                    elem.type === "steps" ?
                    <ProcessWork item = {elem}/>
                    :
                    elem.type === "articles" ?
                    <HotNews item = {elem}/>
                    :
                    elem.type === "workers" ?
                    <Team item = {elem} type={elem.is_background}/>
                    :
                    elem.type === "services" ?
                    <Services item = {elem}/>
                    :
                    elem.type === "services-2" ?
                    <Services2 item = {elem}/>
                    :
                    <div></div>
                  }
                </div>
            )}
        </div>
      }
    </>
  )
}

export default Projects