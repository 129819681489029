import React, {useEffect, useState} from 'react';
import Utils from '../../services/utils';
import ModalComponent from "./ModalComponent";

const VideoModal = ({closeModal, videoUrl, ...props}) => {
    const [isUrl, setIsUrl] = useState();
    const [isVideo, setIsVideo] = useState();

    useEffect(() => {
        if(videoUrl){
            if(Utils.checkurl(videoUrl)){
                setIsUrl(Utils.youtube_parser(videoUrl))
            }else{
                setIsVideo(videoUrl)
            }
        }
    }, [videoUrl])

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className='video-modal'>
                {isUrl && <iframe src={"https://www.youtube.com/embed/" + isUrl}></iframe>}

                {isVideo && <video controls>
                    <source src={isVideo} type="video/mp4"/>
                </video>}
            </div>
        </ModalComponent>
    );
};

export default VideoModal;