import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useFetchItems from '../hooks/useFetchItems'
import { useLangContext } from '../i18n/ProvideLang'
import AppServices from '../services/general/appServices'
import ConfirmActionModal from './complex/ConfirmActionModal'

const Menu = ({item}) => {
    const [logo, setLogo] = useState();
    const [searchEnabled, setSearchEnabled] = useState();
    const [menuButtonEnabled, setMenuButtonEnabled] = useState();
    const [visible, setVisible] = useState();
    const [modalOpen, setModalOpen] = useState();
    const {localizationsItems, lang} = useLangContext();

    const [currentItem, setCurrentItem] = useState();
    const [menuVisible, setMenuVisible] = useState(false);

    const location = useLocation();

    const navigate = useNavigate();

    const [seacrValue, setSearchValue] = useState('');

    const [sticky, setStiky] = useState();

    useEffect(() => {
        setLogo(item?.find(it => it.type === "logo").value)
        setSearchEnabled(item?.find(it => it.type === "search_enabled").value)
        setMenuButtonEnabled(item?.find(it => it.type === "menu_button_enabled").value)
    }, [item])

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')


    const handleChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSearchValue(e.target.value);
    }

    const searchItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/${lang}/articles?term=${seacrValue}`)
        setVisible(false)

    }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 20){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const setDropdown = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        if(currentItem === item){
            setCurrentItem(null)
        }else{
            setCurrentItem(item)
        }
    }

    const openDropDown = () => {
        setMenuVisible(!menuVisible)
    }

  return (
    <div className={sticky ? "navbar-area sticky" : "navbar-area"}>
        <div className="main-responsive-nav">
            <div className="container-fluid plr-100">
                <div className="mobile-nav mean-container">
                    <NavLink to={`/${lang}`} className="logo"><img src={logo} alt="logo" /></NavLink>
                    <ul className="menu-sidebar menu-small-device">
                        {searchEnabled &&<li><button className="popup-button" onClick={() => setVisible(true)}><i className="fas fa-search"></i></button></li>}
                        {menuButtonEnabled && <li><div className="default-button" onClick={() => setModalOpen(true)}>{localizationsItems?.button_content}</div></li>}
                    </ul>
                    <div className='mobile-menu'>
                        <div className='burger-modal' onClick={() => openDropDown()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className={menuVisible ? 'mobile-hidden mobile-hidden-block': 'mobile-hidden'}>
                            <nav>
                                <ul>
                                    {items && items.map((item, index) => 
                                        <li key={index} className='mobile-item' onClick={e => setDropdown(e, item)}>
                                            {
                                                item.items ?
                                                <div className={'mobile-items-link'}>{item.title} <span>+</span></div>
                                                :
                                                <NavLink to={item.value} target={item.type} onClick={() => setMenuVisible(false)}>{item.title}</NavLink>
                                            }
                                            {item.items && 
                                                <ul className={currentItem === item ? 'mob-items-dropdown-visible' : 'mob-items-dropdown'}>
                                                {item.items.map((it, index) =>
                                                    <li key={index}>
                                                        <NavLink to={it.value} target={it.type} className='items-link' onClick={() => setMenuVisible(false)}>{it.title}</NavLink>
                                                    </li>
                                                )}
                                                </ul>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className={sticky ? "main-nav plr-100 sticky" : "main-nav plr-100"}>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-md navbar-light">
                    <NavLink className="navbar-brand" to={`/${lang}`}>
                        <img src={logo} alt="logo" />
                    </NavLink>
                    <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {items && items.map((item, index) => 
                                <li className="nav-item" key={index}>
                                    {
                                        item.items ?
                                        <NavLink to={item.value} target={item.type} className={location.pathname === item.value ? "nav-link dropdown-toggle active-item" : "nav-link dropdown-toggle"}>{item.title}</NavLink>
                                        :
                                        <NavLink to={item.value} target={item.type} className={location.pathname === item.value ? "nav-link active-item" : "nav-link"}>{item.title}</NavLink>
                                    }
                                    {item.items && 
                                        <ul className="dropdown-menu">
                                        {item.items.map((it, index) =>
                                            <li className="nav-item" key={index}>
                                                <NavLink to={it.value} target={it.type} className="nav-link">{it.title}</NavLink>
                                            </li>
                                        )}
                                        </ul>
                                    }
                                </li>
                            )}
                        </ul>
                        <div className="menu-sidebar">
                            <ul>
                                {searchEnabled && <li><button className="popup-button" onClick={() => setVisible(true)}><i className="fas fa-search"></i></button></li>}
                                {menuButtonEnabled && <li><div className="default-button" onClick={() => setModalOpen(true)}>{localizationsItems?.button_content}</div></li>}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div className={visible ? "popup popup-visible" : "popup"}>
            <div className="popup-content">
                <button className="close-btn" id="popup-close" onClick={() => setVisible(false)}><i className="fas fa-times"></i></button>
                <form>
                    <div className="input-group search-box">
                        <input type="text" className="form-control" placeholder="Поиск" value={seacrValue} 
                            onChange={e => handleChange(e)}
                        />
                        <button className="btn" onClick={e => searchItem(e)}><i className="fas fa-search"></i></button>
                    </div>
                </form>
            </div>
        </div>

        <ConfirmActionModal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            onRequestClose={() => setModalOpen(false)}
        />
    </div>
  )
}

export default Menu