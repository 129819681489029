import React, { useEffect } from "react";
import Events from "../../components/Events";
import HotNews from "../../components/HotNews";
import MainBanner from "../../components/MainBanner";
import ProcessWork from "../../components/ProcessWork";
import Services from "../../components/Services";
import StatisticArea from "../../components/StatisticArea";
import Team from "../../components/Team";
import WhyWe from "../../components/WhyWe";
import AppServices from "../../services/general/appServices";
import useFetchItems from '../../hooks/useFetchItems'
import MainBanner2 from "../../components/complex/MainBanner2";
import Services2 from "../../components/Services2";
import FaqSpoiler from "../../components/FaqSpoiler";
import Partners from "../../components/simple/Partners";
import Description from "../../components/simple/Description";
import Gallery from "../../components/complex/Gallery";
import OpeningSlider from "../../components/complex/OpeningSlider";
import { useNavigate, useParams } from "react-router-dom";
import { useLangContext } from "../../i18n/ProvideLang";
import FormContact from "../../components/FormContact";

const Main = () => {
  const navigate = useNavigate();
  const {lang} = useLangContext();
  const {lang: lang2} = useParams();

  useEffect(() => {
    if(lang){
        if(lang !== lang2) {
            navigate(`/${lang}`)
        }
    }
  }, [lang])

  const {
    items,
    loading,
  } = useFetchItems(AppServices.page, 'main')

  return (
    <>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
      <div>
        {items &&
          items.elements?.map((elem, index) => 
            <div key={index}>
              {elem.type === "opening-2" ?
                <MainBanner item = {elem}/>
                :
                elem.type === "opening-3" ?
                <MainBanner2 item = {elem}/>
                :
                elem.type === "opening-slider" ?
                <OpeningSlider item = {elem}/>
                :
                elem.type === "about" ?
                <WhyWe item = {elem}/>
                :
                elem.type === "advantages" ?
                <StatisticArea item = {elem} type={elem.is_background}/>
                :
                elem.type === "events" ?
                <Events item = {elem}/>
                :
                elem.type === "steps" ?
                <ProcessWork item = {elem}/>
                :
                elem.type === "articles" ?
                <HotNews item = {elem}/>
                :
                elem.type === "workers" ?
                <Team item = {elem} type={elem.is_background}/>
                :
                elem.type === "services" ?
                <Services item = {elem}/>
                :
                elem.type === "services-2" ?
                <Services2 item = {elem}/>
                :
                elem.type === "faq" ?
                <FaqSpoiler item = {elem}/>
                :
                elem.type === "partners" ?
                <Partners item = {elem}/>
                :
                elem.type === "text-information" ?
                <Description item = {elem}/>
                :
                elem.type === "gallery" ?
                <Gallery item = {elem}/>
                :
                elem.type === "form" ?
                <FormContact item={elem}/>
                :
                <div></div>
              }
            </div>
          )
        }
      </div>
      }
    </>
  );
};

export default Main;
