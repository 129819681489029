import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const ServiceCard = ({item, border}) => {
    const navigate = useNavigate();
    const {lang, localizationsItems} = useLangContext();

    const handleView = (item) => {
        navigate({
            pathname: `/${lang}/services/${item.slug}`
        })
    }

  return (
    <div className={border ? "service-card mlr-15 mb-30" : "service-card"} onClick={() => handleView(item)}>
        <div className="service-card-img">
            <img src={item.image} alt="image"/>
            <div className='icon-img'>
                <img src={item.icon} alt="" />
            </div>
        </div>
        <div className="service-card-text">
            <h4>{item.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            <div className="read-more-btn" >{localizationsItems?.more}</div>
        </div>
    </div>
  )
}

export default ServiceCard