import React from 'react'
import ProcessCard from './simple/ProcessCard'

const ProcessWork = ({item, type}) => {
  return (
        <section className="precess ptb-70 mt-10">
            <div className="container">
                <div className={type ? "mini-section-title default-section-title-middle" : "default-section-title default-section-title-middle"}>
                    <h3>{item.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        {item?.elements.map((card, index) => 
                            <div className={type ? "col-lg-6 col-md-6 col-sm-6 col-12 mb-3" : "col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"} key={index} >
                                <ProcessCard card={card}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ProcessWork