import React, { useEffect, useState } from 'react'
import WorkerModal from '../complex/WorkerModal';

const TeamCard = ({card}) => {
    const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
        <div className="team-card" onClick={() => setModalOpen(true)}>
            <div className="team-card-img">
                <img src={card?.image} alt="image"/>
            </div>
            <div className="team-card-text">
                <h4>{card?.name}</h4>
                <p className='position'>{card?.position}</p>
                {/*<div dangerouslySetInnerHTML={{ __html: card.description }}></div>*/}
            </div>
        </div>
        {modalOpen && <WorkerModal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            onRequestClose={() => setModalOpen(false)}
            card={card}
        />}
    </div>
  )
}

export default TeamCard