import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import useFetchItems from '../../hooks/useFetchItems'
import PartnersServices from '../../services/general/partnersServices'

const Partners = ({item}) => {
    const {
        items,
    } = useFetchItems(PartnersServices.index, null)

    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 2000,
        autoplay: true,
        loop: true,
        dots: false,
        nav: true,
        navText: ['<i class="fas fa-long-arrow-alt-left"></i>', '<i class="fas fa-long-arrow-alt-right"></i>'],
        responsive: {
            0: {
                items: 3,
            },
            768: {
                items: 3,
            },
            992: {
                items: 5,
            },
            1200: {
                items: 6,
            },
        }
    }

  return (
    <section className="services pt-70 pb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                {items && <OwlCarousel className='service-slider-area-1 owl-carousel' {...options}>
                    {items?.items.map((card, index) =>
                        <div key={index} className='partners-item'>
                            <img src={card.image} alt="" />
                        </div>
                    )}
                </OwlCarousel>}
            </div>
        </div>
    </section>
  )
}

export default Partners