import React from 'react'
import useFetchItems from '../hooks/useFetchItems'
import ProjectServices from '../services/general/projectServices'
import Pagination from './Pagination'
import { useNavigate } from 'react-router-dom'
import { useLangContext } from '../i18n/ProvideLang'

const ProjectsGrid = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ProjectServices.index, null, 'per_page=10&')

    const handleView = (item) => {
        navigate({
            pathname: `/${lang}/projects/${item.slug}`
        })
    }

  return (
        <section className="project pt-70 pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    {items?.items?.map((item, index) => 
                        <div className={(index === 0 || index === 1) ? "col-lg-6 col-md-6 col-sm-12 col-12" : "col-lg-3 col-md-6 col-sm-12 col-12"} key={index}>
                            <div className="service-card-4">
                                <img src={item.preview_image} alt="image"/>
                                <div className="service-card-4-text">
                                    <span>{item.subtitle}</span>
                                    <h5 onClick={() => handleView(item)}>{item.title}</h5>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='mt-30'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </section>
  )
}

export default ProjectsGrid