import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import EventCard from './simple/EventCard';
import EventsServices from '../services/general/eventsServices';
import useFetchItems from '../hooks/useFetchItems';

const Events = ({item}) => {

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
        setFilterData 
      } = useFetchItems(EventsServices.index);
      
    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 2000,
        autoplay: true,
        loop: true,
        dots: false,
        margin: 30,
        nav: true,
        navText: ['<i class="fas fa-long-arrow-alt-left"></i>', '<i class="fas fa-long-arrow-alt-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        }
    }

  return (
        <section className="events ptb-70">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        {items?.items?.slice(0, 3).map((card, index) => 
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                                <EventCard card={card} key={index} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Events