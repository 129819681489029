import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EventDetailContainer from '../../components/EventDetailContainer'
import PageTop from '../../components/PageTop'
import EventsServices from '../../services/general/eventsServices'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import { useLangContext } from '../../i18n/ProvideLang'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const EventDetail = () => {
  const {slug} = useParams();
  const {lang} = useLangContext();
  const navigate = useNavigate();
  const {lang: lang2} = useParams();

  const {info, loading} = useFetchInfo(EventsServices.view, slug, null, lang);

  useEffect(() => {
    if(lang){
        if(lang !== lang2) {
            navigate(`/${lang}/events/${slug}`)
        }
    }
  }, [lang])

  return (
    <>
      <Helmet>
        <title>{info?.title}</title>
      </Helmet>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
        <div>
            <PageTop item={info}/>
            {info && <EventDetailContainer info={info}/>}
        </div>
      }
    </>
  )
}

export default EventDetail