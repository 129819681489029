import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import PageTop from '../../components/PageTop'
import ServicesGrid from '../../components/ServicesGrid'
import StatisticArea from '../../components/StatisticArea'
import Team from '../../components/Team'
import ThreeImg from '../../components/ThreeImg'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'

const About = () => {
  const {
    items,
    loading,
    pageCount,
    params,
    handlePageClick,
    setFilterData
  } = useFetchItems(AppServices.page, 'about')

  return (
    <div>
      <PageTop item={items}/>
    </div>
  )
}

export default About