import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../hooks/useFetchItems';
import { useLangContext } from '../i18n/ProvideLang';
import AppServices from '../services/general/appServices';

const Footer = ({item}) => {
    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [text, setText] = useState();

    const {localizationsItems, lang} = useLangContext();

    const [visible, setVisible] = useState(false)
 
    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials"))
            setLogo(item.find(it => it.type === "white-logo").value)
            setEmail(item.find(it => it.type === "email"))
            setAddress(item.find(it => it.type === "address"))
            setPhone(item.find(it => it.type === "phone"))
            setText(item.find(it => it.type === "footer_text"))
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

        useEffect(() => {
            window.addEventListener('scroll', toggleVisible)
        }, [])

  return (
        <section className="footer">
            <div className="container">
                <div className="footer-content ptb-100">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="footer-logo-area">
                                {logo && <NavLink to={`/${lang}`}><img src={logo} className='footer-logo' alt="image"/></NavLink>}
                                {text?.value.map((em, index) => 
                                    <p key={index}>{em}</p>
                                )}
                                {socials?.value.find(el => el.value) &&
                                <div className="footer-social-area">
                                    <ul>
                                        <li><span>{localizationsItems?.socials} </span></li>
                                        {socials.value.map((social, index) => 
                                            social.value &&
                                            <li key={index}><NavLink to={social.value} target="_blank">
                                                <i className={
                                                    social.type === "facebook" ? "fab fa-facebook-f"
                                                    :
                                                    social.type === "twitter" ? "fab fa-twitter"
                                                    :
                                                    social.type === "instagram" ? "fab fa-instagram"
                                                    :
                                                    social.type === "youtube" ? "fab fa-youtube"
                                                    :
                                                    social.type === "telegram" ? "fab fa-telegram"
                                                    :
                                                    social.type === "whatsapp" ? "fab fa-whatsapp"
                                                    :
                                                    "fab fa-facebook-f"
                                                }>
                                                </i>
                                            </NavLink></li>
                                        )}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="footer-links footer-contact">
                                <h3>{localizationsItems?.connect_us}</h3>
                                {address &&
                                    <div className="footer-contact-card">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h5>{localizationsItems?.address}</h5>
                                        {address.value.map((em, index) => 
                                            <p key={index}>{em}</p>
                                        )}
                                    </div>
                                }
                                {email &&
                                    <div className="footer-contact-card">
                                        <i className="fas fa-envelope"></i>
                                        <h5>{localizationsItems?.email} </h5>
                                        {email.value.map((em, index) => 
                                            <p key={index}><a href={`mailto:${em}`}>{em}</a></p>
                                        )}
                                    </div>
                                }
                                {phone && 
                                    <div className="footer-contact-card">
                                        <i className="fas fa-phone-alt"></i>
                                        <h5>{localizationsItems?.phone}</h5>
                                        {phone.value.map((phone, index) => 
                                            <p key={index}><a href={`tel:${phone}`}>{phone}</a></p>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                        {items?.length ? <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="footer-links footer-quick-links">
                                <h3>{localizationsItems?.main_links}</h3>
                                <ul>
                                    {items?.map((item, index) => 
                                        <li key={index}><i className="fas fa-angle-right"></i> <NavLink to={item.value}>{item.title}</NavLink> </li>
                                    )}
                                </ul>
                            </div>  
                        </div> : <></>}
                        {/*<div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="footer-links footer-newsletter">
                                <h3>Подписаться</h3>
                                <p>Подпишитесь на наш Новостной Канал чтобы быть в курсе новых обновлений</p>
                                <form className="newsletter-form" data-toggle="validator">
                                    <input type="email" className="input-newsletter form-control"  placeholder="Ваш Email" name="EMAIL" required autoComplete="off"/>
                                    <button className="default-button news-btn">Подпишитесь Сейчас</button>
                                    <div id="validator-newsletter" className="form-result"></div>
                                </form>
                            </div>
  </div>*/}
                    </div>
                </div>
                <div className="copyright">
                    <p>© ТОО Canvas, 2012</p>
                </div>
            </div>

            <div className={visible ? "go-top active" : "go-top"} onClick={scrollToTop} ><i className="fas fa-chevron-up"></i></div>
        </section>
  )
}

export default Footer