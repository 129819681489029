import React from 'react'
import useFetchItems from '../hooks/useFetchItems'
import ArticleServices from '../services/general/articleServices'
import NewsCard from './simple/NewsCard'

const HotNews = ({item}) => {
    const { 
        items,
    } = useFetchItems(ArticleServices.index);

  return (
        <section className="blog ptb-100 bg-f9fbfe">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <h3>{item.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        {items?.items.slice(0, 3).map((card, index) => 
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                                <NewsCard item={card} key={index} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        
  )
}

export default HotNews