import React from 'react'
import TeamCard from './simple/TeamCard'
import TeamCard2 from './simple/TeamCard2'

const Team = ({item, type}) => {
  return (
        <section className={type ? "team ptb-70" : "team ptb-70 bg-f9fbfe"}>
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <h3>{item?.title}</h3>
                    {item && <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                </div>
                <div className="section-content">
                    {item.is_component ?
                        <div className="row justify-content-center">
                            {item.elements.map((card, index) => 
                                <TeamCard2 card={card} key={index} />
                            )}
                        </div>
                        :
                        <div className="row justify-content-center">
                            {item.elements.map((card, index) => 
                                <TeamCard card={card} key={index} />
                            )}
                        </div>
                    }
                </div>
            </div>
        </section>
  )
}

export default Team