import About from "../pages/about/About";
import Blog from "../pages/blog/Blog";
import BlogDetail from "../pages/blog/BlogDetail";
import Contacts from "../pages/contact/Contacts";
import Error from "../pages/error/Error";
import EventDetail from "../pages/events/EventDetail";
import EventsPage from "../pages/events/EventsPage";
import Main from "../pages/main/Main";
import ProjectDetail from "../pages/projects/ProjectDetail";
import Projects from "../pages/projects/Projects";
import SamplePage from "../pages/sample/SamplePage";
import ServiceDetail from "../pages/services/ServiceDetail";
import ServicesPage from "../pages/services/ServicesPage";
import { ABOUT_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, BLOG_RIGHT_SIDEVAR, BLOG_ROUTE, CONTACTS_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENTS_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, FAQ_PAGE_ROUTE, MAIN_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: Main
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: Main
    },
    {
        path: ABOUT_PAGE_ROUTE,
        Component: About
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: Blog
    },
    {
        path: CONTACTS_PAGE_ROUTE,
        Component: Contacts
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: Projects
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServiceDetail
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: BlogDetail
    }
]