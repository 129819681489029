import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ServiceCard from './simple/ServiceCard';
import useFetchItems from '../hooks/useFetchItems';
import ServiceServices from '../services/general/serviceServices';

const Services = ({item}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index, null)

    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 2000,
        autoplay: true,
        loop: true,
        dots: false,
        nav: true,
        navText: ['<i class="fas fa-long-arrow-alt-left"></i>', '<i class="fas fa-long-arrow-alt-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        }
    }

  return (
    <section className="services pt-70 pb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                {items && 
                <OwlCarousel className='service-slider-area-1 owl-carousel' {...options}>
                    {items?.items.map((card, index) =>
                        <ServiceCard item={card} key={index} border={true}/>
                    )}
                </OwlCarousel>}
            </div>
        </div>
    </section>
  )
}

export default Services