import React, { useEffect, useState } from 'react'
import useFetchItems from '../hooks/useFetchItems';
import AppServices from '../services/general/appServices';
import img1 from "../images/main-banner.png";

const MainBanner = ({item}) => {
    const [imgFront, setImgFront] = useState();
    const [imgBack, setImgBack] = useState();
    const [imgLeft, setImgLeft] = useState();
    const [socials, setSocials] = useState();

    const { 
        items,
      } = useFetchItems(AppServices.settings);

    useState(() => {
        setImgFront(item.elements.find(item => item.type == 'banner-front')?.image)
        setImgBack(item.elements.find(item => item.type == 'banner-back')?.image)
        setImgLeft(item.elements.find(item => item.type == 'left-image')?.image)
    }, [item])

    useEffect(() => {
        setSocials(items?.find(it => it.type === "socials")?.value)
    }, [items])


  return (
    <section className="main-banner plr-100 main-banner-color">
            <div className="banner-social-icons">
                <ul>
                    {socials?.map((social, index) => 
                        social.value &&
                        <li key={index}><a href={social.value} target="_blank">
                            <i className={
                                        social.type === "facebook" ? "fab fa-facebook-f"
                                        :
                                        social.type === "twitter" ? "fab fa-twitter"
                                        :
                                        social.type === "instagram" ? "fab fa-instagram"
                                        :
                                        social.type === "youtube" ? "fab fa-youtube"
                                        :
                                        social.type === "telegram" ? "fab fa-telegram"
                                        :
                                        social.type === "whatsapp" ? "fab fa-whatsapp"
                                        :
                                        "fab fa-facebook-f"
                            }></i>
                        </a></li>
                    )}
                </ul> 
            </div>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-text-area banner-text-area-1 banner-img-area-1">
                            <h6>{item.subtitle}</h6>
                            <h1>{item.title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-img-area-1">
                            <img src={imgFront} alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
        <div className='main-banner-before'>
            <img src={imgBack} alt="" />
        </div>
        <div className='main-banner-after'>
            <img src={imgLeft} alt="" />
        </div>
    </section>
  )
}

export default MainBanner