import React from 'react'

const Description = ({item}) => {
  return (
    <section className="ptb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
            </div>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
        </div>
    </section>
  )
}

export default Description