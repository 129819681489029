import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

const ContactItem = ({item}) => {
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    useEffect(() =>{
        if(item){
            setAddress(item.elements.find(it => it.type === "address"))
            setPhone(item.elements.find(it => it.type === "phone"))
            setEmail(item.elements.find(it => it.type === "email"))
        }
    }, [item])

    console.log(item)
  return (
        <section className="contcat-card-area pt-70 pb-70">
            <div className="container">
                <div className='row'>
                    <div>
                        {item?.description && <p dangerouslySetInnerHTML={{ __html: item.description}}></p>}
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="contact-card">
                            <i className="fas fa-map-marker-alt"></i>
                            <h5>{address?.title}</h5>
                            <p>{address?.value}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="contact-card">
                            <i className="fas fa-envelope"></i>
                            <h5>{email?.title}</h5>
                            {email?.value.map((mail, index) =>
                                <p key={index}><a href={`mailto:${mail}`}>{mail}</a></p>
                            )}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="contact-card">
                            <i className="fas fa-phone-alt"></i>
                            <h5>{phone?.title}</h5>
                            {phone?.value.map((ph, index) => 
                                <p key={index}><a href={`tel:${ph}`}>{ph}</a></p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ContactItem